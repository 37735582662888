import React from 'react';
import Layout from '../components/Shared/Layout';

import Verticals from '../components/Verticals';
import '../assets/css/verticals.css';
import * as ReactGA from 'react-ga';

const $ = typeof window !== `undefined` ? require('jquery') : null;
const bootstrap = typeof window !== `undefined` ? require('bootstrap') : null;
// import 'bootstrap';

class VerticalsPage extends React.Component {
  /* constructor(props) {
      super(props);
  } */
  componentDidMount() {
    ReactGA.set({
      title: 'Verticals.Page',
    });
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return (
      <Layout>
        <Verticals edtech={false} games={false} vr={false} iot healthtech={false} fintech={false} />
      </Layout>
    );
  }
}

export default VerticalsPage;
